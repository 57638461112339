/*------------------------- ui-core framework.css -----------------------
  Base
-----------------------------------------------------------------------*/

body {
    background: var(--COLOR-DARK-BG);
    color: var(--text);
    font-family: var(--font-family);
    line-height: var(--line-height);
    font-size: var(--font-size);
    letter-spacing: var(--letter-spacing);
  }
  
  html,
  body,
  /* #root, */
  .uic-root {
    height: 100%;
    width: 100%;
  }
  
  img {
    max-width: 100%;
  }
  
  small {
    color: #aaa;
    /* font-size: 80%; */
  }
  
  hr {
    margin: 0;
    margin-left: auto;
    margin-right: auto;
    border: none;
    border-top: 1px solid #303030;
  }
  
  .intent-mouse *:focus {
    outline: none;
  }
  
  /*-----------------------------------------------------------------------
    Anchor Link
  -----------------------------------------------------------------------*/
  a:visited,
  a:link {
    color: hsl(
      var(--primary-h), 
      calc(var(--primary-s) - 20%),
      80%
    );
    /* background: hsla(
      var(--primary-h), 
      calc(var(--primary-s) - 10%),
      calc(var(--primary-l) + 15%),
      0.1
    ); */
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
  
  .nav a {
    color: unset;
    background: unset;
    text-decoration: none;
  }
  
  /*-----------------------------------------------------------------------
    Routing
  -----------------------------------------------------------------------*/
  
  :root {
    --NAV-WIDTH: 180px;
    --SITE-PADDING: 16px;
    --topBarHeight: 55px;
  }
  
  @media (max-width: 1352px) {
    :root {
      --NAV-WIDTH: 140px;
      --SITE-PADDING: 8px;
    }
  }
  
  .uic-noNav {
    --NAV-WIDTH: 0px;
  }
  .uic-noNav .nav {
    display: none;
  }
  .uic-noNav .mobileNavBtn {
    display: none !important;
  }
  
  /*––––––––––––––– Top Bar –––––––––––––––*/
  
  .topBar {
    position: fixed;
    height: var(--topBarHeight);
    width: 100%;
    min-width: max-content;
    overflow: hidden;
    z-index: 2;
    background: hsl(20, 3%, 15%);
    border-top: 2px solid hsl(21, 75%, 50%);
    box-shadow: 
      0px 0px 1px 1px #0005, 
      var(--NAV-WIDTH) 0px 6px 0px #0006;
  }
  
  @supports (backdrop-filter: blur(5px)) {
    .topBar {
      background: hsla(21, 5%, 17%, 0.75);
      backdrop-filter: blur(5px);
    }
  }
  
  .productLogo {
    color: hsl(205, 90%, 75%);
    display: inline-block;
    height: 100%;
    padding: 13px;
    margin-left: 3px;
    user-select: none;
    text-decoration: none !important;
  }
  
  .productLogo img {
    height: 100%;
    transition: filter 120ms;
  }
  .productLogo:hover img {
    filter: contrast(0) sepia(100%) hue-rotate(170deg) brightness(1.15) saturate(2.5);
    transition: filter 50ms;
  }
  
  .productTitle {
    font-weight: 600;
    font-size: 34px;
    line-height: 27px;
    color: #ccc;
    white-space: nowrap;
  }
  .productTitle:hover {
    color: var(--primary-light);
  }
  
  .telosLogo {
    float: right;
    display: inline-block;
    height: 100%;
    padding: 10px 16px;
    user-select: none;
  }
  
  /*––––––––––––––– Main –––––––––––––––*/
  
  .main {
    position: absolute;
    overflow: auto;
    padding: var(--SITE-PADDING);
    left: var(--NAV-WIDTH);
    width:  calc(100% - var(--NAV-WIDTH));
    height: 100%;
    padding-top: calc(var(--topBarHeight) + var(--SITE-PADDING));
  }
  
  .main-brandLogo {
    float: right;
    padding-bottom: 8px;
    padding-right: 8px;
    padding-left: 8px;
    opacity: 0.6;
    width: 264px;
    user-select: none;
  }
  
  /*––––––––––––––– Nav ––––––––––––––––*/
  
  .nav {
    position: fixed;
    left: 0;
    top: var(--topBarHeight);
    height: calc(100% - var(--topBarHeight));
    width: var(--NAV-WIDTH);
    background: var(--bg-nav);
    z-index: 1;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25), 
          inset -1px 0px 0px 0px #9991;
    border-top: 2px groove hsla(0, 0%, 20%, 1);
    transition: width 150ms, left 150ms;
  }
  
  .nav-innerScroll {
    height: 100%;
    width: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .nav-list {
    flex: 1 0 auto;
    padding-top: 16px;
    padding-bottom: 40px;
  }
  
  .nav ul {
    list-style: none;
    overflow: auto;
  }
  
  .nav-item {
    padding-left: 3px;
  }
  .nav-item.active {
    border-left: 3px solid var(--primary);
    padding-left: 0px;
    background: hsla(205, 50%, 4%, 0.12);
  }
  
  .nav-item-name {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .nav-icon-container {
    margin-right: 8px;
  }
  
  .nav-link svg {
    /* float: left; */
    display: flex;
    margin-right: 12px;
    width: 20px;
    height: 20px;
    /* filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.3)); */
  }
  
  .nav-link {
    display: flex;
    align-items: center;
    padding: 9px 6px 9px 12px;
    font-size: 18px;
    text-decoration: none;
    white-space: nowrap;
    color: hsl(0, 0%, 70%);
    background: none;
    transition: padding 90ms;
  }
  
  .nav hr {
    margin: 10px 0;
    border-color: var(--COLOR-BORDER-1);
  }
  
  /*––––––––––– Sub –––––––––––*/
  
  .nav-sub-list {
    display: none;
  }
  
  .nav-subLink {
    display: block;
    padding: 9px 6px 9px 45px;
    font-size: 18px;
    text-decoration: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: hsl(0, 0%, 70%);
  }
  
  .flyOut-top-item {
    border-bottom: 2px groove #5554;
    margin-top: -1px;
    font-weight: 500;
  }
  
  /*––––––––––– States –––––––––––*/
  
  .nav-item:not(.active):hover,
  .nav-item.active .nav-link:hover,
  .nav-sub-item:hover {
    box-shadow: inset 0 0 0 100px #aaa2;
  }
  
  .nav-link.active,
  .nav-subLink.active {
    background: hsla(0, 0%, 0%, 0.22) !important;
    color: hsl(
      var(--primary-h),
      var(--primary-s),
      calc(var(--primary-l) + 17%)
    );
    text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.4);
    font-weight: 500;
  }
  
  
  .nav-item:not(.active) .nav-sub-container,
  .navCollapsed .nav-item.active:hover .nav-sub-container {
    position: absolute;
    left: -200px;
  }
  
  .nav-item:not(.active):hover .nav-sub-list,
  .navCollapsed .nav-item.active:hover .nav-sub-list {
    display: block;
    position: relative;
    left: calc(var(--NAV-WIDTH) + 201px);
    top: -41px;
    background: hsl(0, 0%, 19%);
    border: 1px solid hsl(0, 0%, 24%);
    border-left-color:  hsl(0, 0%, 20%);
    border-radius: 1px 4px 4px 1px;
    box-shadow: 3px 1px 7px 0px #0005;
    /* transform: translate(200px, 0px); */
  }
  .nav:not(.navCollapsed) .nav-item.active .nav-sub-list {
    display: block;
  }
  
  .nav-item:not(.active) .nav-subLink,
  .navCollapsed .nav-subLink {
    padding-left: 15px;
    padding-right: 12px;
  }
  
  .navCollapsed .nav-link {
    padding-left: 7px;
  }
  .navCollapsed .nav-subLink,
  .navCollapsed .nav-item-name {
    text-overflow: clip;
  }
  
  /* hitbox */
  .nav-item:not(.active):hover::after,
  .navCollapsed .nav-item.active:hover::after {
    content: "";
    position: absolute;
    right: 0px;
    height: 45px;
    width: calc(var(--NAV-WIDTH) / 5);
  }
  
  
  @media (min-width: 767.98px) and (max-width: 1352px) {
    /* item */
    .nav:not(.navCollapsed) .nav-link {
      font-size: 16px;
      padding: 9px 2px 9px 6px;
    }
    .nav:not(.navCollapsed) .nav-link svg {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
    /* sub item */
    .nav:not(.navCollapsed) .nav-subLink {
      font-size: 15px;
      padding: 9px 2px 9px 18px;
    }
    .nav:not(.navCollapsed) .nav-item:not(.active):hover .nav-subLink {
      padding-right: 18px;
    }
    .nav:not(.navCollapsed) .nav-item:not(.active):hover .nav-sub-list {
      top: -38px;
    }
  }
  
  /*––––––––––– Mobile –––––––––––*/
  
  .mobile-overlay {
    display: none;
  }
  
  .mobileNavBtn.MuiSvgIcon-root {
    display: none;
    height: 100%;
    padding: 14px;
    padding-right: 10px;
    width: 55px;
    float: left;
    cursor: pointer;
  }
  .mobileNavBtn.MuiSvgIcon-root:hover {
    color: #bbb;
  }
  
  @media (max-width: 767.98px) {
    :root {
      --NAV-WIDTH: 200px;
    }
    .main {
      left: 0;
      width: 100%;
    }
    .nav:not(.mobileNavOpen) {
      left: -200px;
    }
  
    .mobileNavBtn.MuiSvgIcon-root {
      display: inline-block;
    }
  
    .mobile-overlay.mobileNavOpen {
      display: block;
      position: fixed;
      top: var(--topBarHeight);
      height: 100%;
      width: 100%;
      background-color: #000;
      opacity: 0.35;
    }
  
    .productLogo {
      margin-left: 0px;
      padding-left: 4px;
    }
    .telosLogo { 
      padding-right: 10px; 
    }
  
    .nav-collapseBtn {
      display: none;
    }
  }
  
  /*––––––––––– Collapse Btn –––––––––––*/
  
  .nav-collapseBtn {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 40px;
    padding: 7px;
    cursor: pointer;
    border: 0 2px 0 0;
    background: inherit;
  }
  
  .nav-collapseBtn svg {
    height: 26px;
    width: 26px;
    color: #555;
    transition: transform 150ms;
  }
  
  .nav-collapseBtn:hover svg {
    color: var(--COLOR-TXT-VALUE);
  }
  
  .navCollapsed .nav-collapseBtn svg {
    transform: rotate(180deg);
  }
  
  .nav-collapseBtn:hover svg {
    box-shadow: inset 0 0 0px 20px var(--bg-nav), 0 0 10px 5px var(--bg-nav);
    border-radius: 20px;
  }
  
  /*––––––––––––––– tab index –––––––––––––––*/
  .nav object {
    pointer-events: none;
  }
  
  /*––––––––––––––– Route Error –––––––––––––––*/
  
  .uic-routingCaughtError {
    margin: 8px;
    margin-top: 54px;
    padding: 24px;
    background-color: rgba(210, 60, 70, 0.12);
    font-size: 18px;
  }
  .uic-routingCaughtError > p {
    padding: 24px 0px 8px 0px;
    font-weight: 300;
  }
  .uic-routingCaughtError > a,
  .uic-routingCaughtError b {
    font-weight: 500;
  }
  
  /*-----------------------------------------------------------------------
    Snackbar Notification
  -----------------------------------------------------------------------*/
  
  .uic-snackbar > div {
    padding: 4px 16px;
    font-size: 18px;
    text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.35);
  }
  
  .uic-snackbar .uic-snackbar-error {
    background-color: rgba(130, 0, 0, 0.8);
    border: 1px solid rgb(220, 0, 0);
  }
  .uic-snackbar .uic-snackbar-warning {
    background-color: rgba(201, 137, 0, 0.8);
    border: 1px solid rgb(255, 190, 0);
  }
  .uic-snackbar .uic-snackbar-info {
    background-color: rgb(0, 85, 210, 0.8);
    border: 1px solid rgb(0, 130, 255);
  }
  .uic-snackbar .uic-snackbar-success {
    background-color: rgba(14, 105, 16, 0.8);
    border: 1px solid rgb(10, 165, 10);
  }
  
  .uic-snackbar-closeBtn {
    font-size: 18px;
    color: rgb(200, 200, 200);
    text-decoration: none !important;
    border: none;
    cursor: pointer;
    background: none;
    margin-left: -8px;
    height: 32px;
    width: 32px;
    border-radius: 16px;
  }
  
  .uic-snackbar-closeBtn:hover {
    color: rgb(180, 180, 180);
    background: rgba(0, 0, 0, 0.08);
  }
  
  /*-----------------------------------------------------------------------
    Loading Bar
  -----------------------------------------------------------------------*/
  .uic-loadingBar.MuiLinearProgress-root {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    z-index: 3;
    background-color: transparent;
    background-color: hsl(21, 75%, 32%);
    overflow: visible;
  }
  .uic-loadingBar .MuiLinearProgress-barColorPrimary {
    background-color: hsl(21, 100%, 55%);
  }
  .uic-loadingBar {
    display: none;
  }
  
  .uic-loadingBar-enter {
    display: block;
    opacity: 0;
  }
  .uic-loadingBar-enter-active {
    display: block;
    opacity: 0.99;
    transition: opacity 400ms;
  }
  .uic-loadingBar-enter-done {
    display: block;
    opacity: 0.99;
  }
  .uic-loadingBar-exit {
    display: block;
    opacity: 0;
    transition: opacity 250ms;
  }
  
  /*-----------------------------------------------------------------------
    Scrollbar - override for non-hiding scrollbars (no firefox support)
    Conditionally enabled in appInit.js
  -----------------------------------------------------------------------*/
  
  .customScrollbars ::-webkit-scrollbar {
    width: 8px;
  }
  
  .customScrollbars ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
  }
  
  .customScrollbars ::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.35);
    border: 1px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
  }
  
  .customScrollbars .main::-webkit-scrollbar {
    width: 14px;
  }
  .customScrollbars .main::-webkit-scrollbar-thumb {
    border: 4px solid transparent;
  }
  
  /*-----------------------------------------------------------------------
    Util
  -----------------------------------------------------------------------*/
  
  .width-fit-content {
    width: fit-content !important;
  }
  
  .displayBlock {
    display: block !important;
  }
  
  .centerX {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .centerY {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  
  .no-wrap {
    white-space: nowrap;
  }
  
  .show {
    border: 1px solid rgb(255, 20, 255, 0.35);
  }
  
  /*-----------------------------------------------------------------------
    Misc
  -----------------------------------------------------------------------*/
  
  .uic-hr {
    margin: 16px 0px;
    border-color: #333;
  }
  
  .shake-animation {
    animation: shake 0.5s cubic-bezier(0, 0.38, 0.1, 0.98) both;
    transform: translateX(0);
  }
  
  @keyframes shake {
    10%,
    90% {
      transform: translateX(-1px);
    }
  
    20%,
    80% {
      transform: translateX(2px);
    }
  
    30%,
    50%,
    70% {
      transform: translateX(-3px);
    }
  
    40%,
    60% {
      transform: translateX(3px);
    }
  }
  
  .uic-modal-btnGroup .uic-btn {
    margin-left: 10px;
  }